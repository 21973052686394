import {
	createRouter,
	createWebHistory
} from "vue-router";
import layout from "../layout/index";
import {
	initRouter
} from "@/utils/routerUtil.js";

// 不需要登录拦截的路由配置
export const loginIgnore = {
	names: ["404", "403"], //根据路由名称匹配
	paths: ["/login"], //根据路由fullPath匹配
	/**
	 * 判断路由是否包含在该配置中
	 * @param route vue-router 的 route 对象
	 * @returns {boolean}
	 */
	includes(route) {
		return this.names.includes(route.name) || this.paths.includes(route.path);
	},
};

// 不需要展示到左侧菜单栏的路由
export const constantRoutes = [{
	path: "/login",
	name: "login",
	component: () => import("@/views/login/index"),
},
{
	path: "/403",
	name: "403",
	meta: {
		title: '权限不足'
	},
	component: () => import("@/views/exception/403"),
},
];

export let asyncRoutes = [
// 首页
{
	path: "/",
	name: "home",
	redirect: "/home",
	meta: {
		title: '首页',
		icon: "BankOutlined",
	},
	component: layout,
	children: [{
		path: "home",
		name: "home",
		meta: {
			title: '首页',
			icon: "ShopOutlined",
		},
		component: () => import("@/views/home/index.vue"),
	}]
},
// 系统
	{
		path: "/system",
		name: 'system',
		meta: {
			title: '系统',
			icon: "ToolOutlined",
			permission: "system",
		},
		component: layout,
		children: [{
			path: "systemRoot",
			name: "systemRoot",
			meta: {
				title: "系统管理",
				icon: "ToolOutlined",
				permission: "system_manage",
			},
			component: () => import("@/views/system/role/role"),
			children: [{
				path: "role",
				name: "role",
				meta: {
					title: "角色管理",
					icon: "UsergroupDeleteOutlined",
					permission: "system_manage_role",
				},
				component: () => import("@/views/system/role/role"),
				children: [{
					path: "view",
					name: "roleView",
					meta: {
						title: "角色列表",
						permission: "system_manage_role",
					},
					hidden: true,
					component: () => import("@/views/system/role/role"),
				},
				],
			},
				{
					path: "user",
					name: "systemUser",
					meta: {
						title: "用户管理",
						icon: "UserOutlined",
						permission: "system_manage_user",
					},
					component: () => import("@/views/system/user/user"),
					children: [{
						path: "view",
						name: "userView",
						meta: {
							title: "用户列表",
							icon: "UserOutlined",
							permission: "system_manage_user",
						},
						hidden: true,
						component: () => import("@/views/system/user/user"),
					},
						{
							path: "userAdd",
							name: "user_add",
							meta: {
								title: "新增用户",
								permission: "system_manage_user_add",
							},
							hidden: true,
							component: () => import("@/views/system/user/userAdd"),
						},
						{
							path: "userEdit",
							name: "userEdit",
							meta: {
								title: "用户编辑",
								permission: "system_manage_user_edit",
							},
							hidden: true,
							component: () => import("@/views/system/user/userEdit"),
						},
					],
				},

				{
					path: "systemLog",
					name: "systemLog",
					meta: {
						title: "日志管理",
						icon: "ScheduleOutlined",
						permission: "system_manage_log",
					},
					component: () => import("@/views/system/systemLog/systemLog"),
				},
				{
					path: "menu",
					name: "menu",
					meta: {
						title: "菜单管理",
						icon: "ScheduleOutlined",
						permission: "system_manage_menu",
					},
					component: () => import("@/views/system/menu/menu"),
					children: [{
						path: "view",
						name: "menuView",
						meta: {
							title: "菜单列表",
							permission: "system_manage_menu",
						},
						hidden: true,
						component: () => import("@/views/system/menu/menu"),
					},
						{
							path: "add",
							name: "menuAdd",
							meta: {
								title: "新增菜单",
								permission: "system_manage_menu_add",
							},
							hidden: true,
							component: () => import("@/views/system/menu/add"),
						},
						{
							path: "edit",
							name: "menuEdit",
							meta: {
								title: "菜单编辑",
								permission: "system_manage_menu_edit",
							},
							hidden: true,
							component: () => import("@/views/system/menu/edit"),
						},
					],
				}
			],
		}, {
			path: "task",
			name: "task",
			meta: {
				title: '任务中心',
				icon: "SnippetsOutlined",
				permission: "system_task",
			},
			component: () => import("@/views/exportTask/list.vue"),
			children: [{
				path: "list",
				name: "taskList",
				meta: {
					title: '导出任务列表',
					permission: "system_task_list",
				},
				component: () => import("@/views/exportTask/list.vue"),
			}, {
				path: 'importList',
				name: 'taskImportList',
				meta: {
					title: '导入任务列表',
					permission: "system_importTask_list",
				},
				component: () => import("@/views/exportTask/importList.vue"),
			}]
		}]
	},
// 影院
	{
		path: "/cinema",
		name: "cinema",
		meta: {
			title: '影院',
			icon: "CameraOutlined",
			permission: "cinema",
		},
		component: layout,
		children: [{
			path: "basis",
			name: "basis",
			meta: {
				title: '影院管理',
				icon: "ShopOutlined",
				permission: "cinema_basis",
			},
			component: () => import("@/views/cinema/basis/info/info.vue"),
			children: [
				{
					path: "info",
					name: "cinemaInfo",
					meta: {
						title: '影院列表',
						permission: "cinema_basis_info",
					},
					component: () => import("@/views/cinema/basis/info/info.vue"),
				},
				// {
				// 	path: 'film',
				// 	name: "filmInfo",
				// 	meta: {
				// 		title: '影片列表',
				// 		permission: "cinema_basis_film",
				// 	},
				// 	component: () => import("@/views/cinema/basis/film/list.vue")
				// },
				{
					path: 'label',
					name: "cinemaLabel",
					meta: {
						title: '影院标签',
						permission: "cinema_basis_filmtags",
					},
					component: () => import("@/views/cinema/basis/label/list.vue")
				}, {
					path: 'traffic',
					name: "traffic",
					meta: {
						title: '交通配置',
						permission: "cinema_basis_traffic",
					},
					component: () => import("@/views/cinema/basis/traffic/list.vue")
				}]
		}, {
			path: 'policy',
			name: 'policy',
			meta: {
				title: '政策设置',
				icon: "HourglassOutlined",
				permission: "cinema_policy",
			},
			component: () => import("@/views/cinema/policy/pricing/pricing.vue"),
			children: [{
				path: "pricing",
				name: "pricing",
				meta: {
					title: '场次基础定价',
					permission: "cinema_policy_pricing",
				},
				component: () => import("@/views/cinema/policy/pricing/pricing.vue"),
			}, {
				path: "refundTicket",
				name: "refundTicket",
				meta: {
					title: '退改签政策',
					permission: "cinema_policy_signature",
				},
				component: () => import("@/views/cinema/policy/refundTicket/list.vue")
			}]
		},
			{
				path: "cityManage",
				name: "cityManage",
				meta: {
					title: '城市管理',
					icon: "SettingOutlined",
					permission: "cinema_cityManage",
				},
				component: () => import("@/views/cinema/cityManage/city/city.vue"),
				children: [{
					path: 'city',
					name: 'city',
					meta: {
						title: '城市配置',
						permission: "cinema_cityManage_city",
					},
					component: () => import("@/views/cinema/cityManage/city/city.vue")
				}]
			},
			{
				path: "service",
				name: "service",
				meta: {
					title: '影院定制服务',
					icon: "CustomerServiceOutlined",
					permission: "cinema_diy",
				},
				component: () => import("@/views/cinema/service/list.vue"),
				children: [{
					path: 'serviceList',
					name: 'serviceList',
					meta: {
						title: '定制服务列表',
						permission: "cinema_diy_list",
					},
					component: () => import("@/views/cinema/service/list.vue")
				}]
			}]
	},
// 影片
	{
		path: "/film",
		name: "film",
		meta: {
			title: '影片',
			icon: "PlaySquareOutlined",
			permission: "film"
		},
		component: layout,
		children: [{
			path: "list",
			name: "filmList",
			meta: {
				title: '影片列表',
				icon: "BarsOutlined",
				permission: "film_list"
			},
			component: () => import("@/views/film/list/list.vue"),
		}, {
			path: "label",
			name: "filmLabel",
			meta: {
				title: '影片标签',
				icon: "FlagOutlined",
				permission: "film_label"
			},
			component: () => import("@/views/film/label/list.vue"),
		}, {
			path: "classify",
			name: "filmClassify",
			meta: {
				title: '影片分类',
				icon: "GoldOutlined",
				permission: "film_classify"
			},
			component: () => import("@/views/film/classify/list.vue"),
		}, {
			path: "people",
			name: "filmPeople",
			meta: {
				title: '人员列表',
				icon: "UserSwitchOutlined",
				permission: "film_performer"
			},
			component: () => import("@/views/film/people/list.vue"),
		}, {
			path: "film",
			name: "reportFormsSignatureFilm",
			meta: {
				title: '电影信息表',
				icon: "ReadOutlined",
				permission:"film_filmInfo"
			},
			component: () => import("@/views/reportForms/session/film/list.vue")
		}, {
			path: "filmMaterials",
			name: "reportFormsSignatureFilmMaterials",
			meta: {
				title: '电影资料表',
				icon: "ProfileOutlined",
				permission:"film_filmMaterials"
			},
			component: () => import("@/views/reportForms/session/filmMaterials/list.vue")
		}, {
			path: "performer",
			name: "reportFormsSignaturePerformer",
			meta: {
				title: '演职人员表',
				icon: "SolutionOutlined",
				permission:"film_performerInfo"
			},
			component: () => import("@/views/reportForms/session/performer/list.vue")
		}]
	},
// 卖品
	{
		path: "/snackManage",
		name: "snackManage",
		meta: {
			title: '卖品',
			icon: "WalletOutlined",
			permission: "snack"
		},
		component: layout,
		children: [{
			path: "setting",
			name: "snackManageSetting",
			meta: {
				title: '卖品基础设置',
				icon: "SettingOutlined",
				permission: "snack_setting"
			},
			component: () => import("@/views/snackManage/snackSetting/classify/classify.vue"),
			children: [{
				path: "classify",
				name: "snackClassify",
				meta: {
					title: "卖品分类",
					permission: "snack_setting_classify"
				},
				component: () => import("@/views/snackManage/snackSetting/classify/classify.vue")
			},
			]
		}, {
			path: "snack",
			name: "snackManageGoods",
			meta: {
				title: '卖品管理',
				icon: "ShoppingCartOutlined",
				permission: 'snack_manage'
			},
			component: () => import("@/views/snackManage/goods/cinemaSnack/cinemaSnack.vue"),
			children: [{
				path: "snackList",
				name: "snackList",
				meta: {
					title: '卖品库',
					permission: 'snack_manage_template'
				},
				component: () => import("@/views/snackManage/goods/snackList/snackList.vue")
			}, {
				path: "cinemaSnack",
				name: "cinemaSnack",
				meta: {
					title: '影院卖品',
					permission: 'snack_manage_cinema'
				},
				component: () => import("@/views/snackManage/goods/cinemaSnack/cinemaSnack.vue"),
			}, {
				path: "boundList",
				name: "boundList",
				meta: {
					title: '出入库记录',
					permission: 'snack_manage_record'
				},
				component: () => import("@/views/snackManage/goods/boundList/list.vue"),
			}]
		},
			{
				path: "order",
				name: "snackManageOrder",
				meta: {
					title: '订单管理',
					icon: "SolutionOutlined",
					permission: "snack_order"
				},
				component: () => import("@/views/reportForms/sale/sales/list.vue"),
				children: [{
					path: "list",
					name: "snackManageOrderList",
					meta: {
						title: '卖品订单',
						permission: "snack_order_list"
					},
					component: () => import("@/views/reportForms/sale/sales/list.vue"),
				}]
			}
		]
	},
// 商城
	{
		path: "/mall",
		name: "mall",
		meta: {
			title: '商城',
			icon: "ShoppingOutlined",
			permission: "mall"
		},
		component: layout,
		children: [
			{
				path: "goods",
				name: "mallGoods",
				meta: {
					title: '商品管理',
					icon: "ShoppingCartOutlined",
					permission: "mall_goods"
				},
				component: () => import("@/views/mall/goods/publish/publish.vue"),
				children: [
					{
						path: "goodsList",
						name: "goodsList",
						meta: {
							title: '商品列表',
							permission: "mall_goods_template"
						},
						component: () => import("@/views/mall/goods/goodsList/goodsList.vue")
					}, {
						path: "classify",
						name: "goodsClassify",
						meta: {
							title: '商品分类',
							permission: "mall_setting_classify"
						},
						component: () => import("@/views/mall/goodsSetting/classify/classify.vue"),
					}, {
						path: "label",
						name: "goodsLabel",
						meta: {
							title: '商品标签',
							permission: "mall_setting_label"
						},
						component: () => import("@/views/mall/goodsSetting/label/label.vue"),
					}]
			}, {
				path: "freightSet",
				name: "mallGoodsSettingFreightSet",
				meta: {
					title: "运费设置",
					icon: "DollarOutlined",
					permission:"mall_freight"
				},
				component: () => import("@/views/mall/goodsSetting/freight/freight.vue"),
				children: [{
					path: "freight",
					name: "mallGoodsSettingFreight",
					meta: {
						title: '运费模板',
						permission:"mall_freight_freight"
					},
					component: () => import("@/views/mall/goodsSetting/freight/freight.vue"),
				}, {
					path: "free",
					name: "mallGoodsSettingFree",
					meta: {
						title: '免运费配置',
						permission:"mall_freight_free"
					},
					component: () => import("@/views/mall/goodsSetting/free/free.vue"),
				}]
			}, {
				path: "mallOrder",
				name: "mallOrder",
				meta: {
					title: "订单管理",
					icon: "SolutionOutlined",
					permission: "mall_order"
				},
				component: () => import("@/views/mall/order/goodsOrder/list.vue"),
				children: [
					{
						path: "goodsOrder",
						name: "mallOrderGoodsOrder",
						meta: {
							title: '商品订单',
							permission: "mall_order_goodsOrder"
						},
						component: () => import("@/views/mall/order/goodsOrder/list.vue"),
					}, {
						path: "afterSale",
						name: "mallOrderAfterSale",
						meta: {
							title: '售后列表',
							permission: "mall_order_afterSale"
						},
						component: () => import("@/views/mall/order/afterSale/list.vue"),
					}, {
						path: "setting",
						name: "mallOrdersetting",
						meta: {
							title: '订单设置',
							permission: "mall_order_setting"
						},
						component: () => import("@/views/mall/order/setting/setting.vue"),
					}]
			}, {
				path: "activity",
				name: "activity",
				meta: {
					title: "活动专场",
					icon: "FireOutlined",
					permission: "mall_activity"
				},
				component: () => import("@/views/mall/activity/hot/list.vue"),
				children: [{
					path: "hot",
					name: "activityHot",
					meta: {
						title: '热卖专场',
						permission: "mall_activity_hot"
					},
					component: () => import("@/views/mall/activity/hot/list.vue"),
				}, {
					path: "integral",
					name: "activityIntegral",
					meta: {
						title: '积分商城装修',
						permission: "mall_activity_integral"
					},
					component: () => import("@/views/mall/activity/integral/integral.vue"),
				}]
			}, {
				path: "hotSearch",
				name: "hotSearch",
				meta: {
					title: "热搜词管理",
					icon: "LikeOutlined",
					permission: "mall_hotSearch"
				},
				component: () => import("@/views/mall/hotSearch/list.vue")
			}]
	},
// 卡券
	{
		path: "/coupon",
		name: "coupon",
		meta: {
			title: '卡券',
			icon: "WalletOutlined",
			permission: "coupon",
		},
		component: layout,
		children: [
			{
				path: "preferential",
				name: "preferential",
				meta: {
					title: '一卡通管理',
					icon: "InboxOutlined",
					permission: "coupon_preferential",
				},
				component: () => import("@/views/coupon/preferential/list/list.vue"),
				children: [{
					path: "rules",
					name: "rules",
					meta: {
						title: '一卡通政策',
						permission: 'coupon_preferential_rules',
					},
					component: () => import("@/views/coupon/preferential/rules/list.vue")
				},
					{
						path: "list",
						name: "preferentialList",
						meta: {
							title: '一卡通管理',
							permission: "coupon_preferential_list",
						},
						component: () => import("@/views/coupon/preferential/list/list.vue"),
					},
					{
						path: "send",
						name: "preferentialSend",
						meta: {
							title: '一卡通发放',
							permission: "coupon_preferential_send",
						},
						component: () => import("@/views/coupon/preferential/send/temp.vue"),
					},
					{
						path: "bindInfo",
						name: "preferentialBindInfo",
						meta: {
							title: '一卡通绑定信息',
							permission: "coupon_preferential_bindInfo",
						},
						component: () => import("@/views/coupon/preferential/bindInfo/infoList.vue")
					}, {
						path: "recharge",
						name: "preferentialRecharge",
						meta: {
							title: '一卡通充值活动',
							permission: "coupon_preferential_Recharge",
						},
						component: () => import("@/views/coupon/preferential/Recharge/list.vue")
					}, {
						path: "record",
						name: "preferentialRecordList",
						meta: {
							title: '一卡通批量生成记录',
							permission: "coupon_preferential_record",
						},
						component: () => import("@/views/coupon/preferential/record/list.vue")
					}, {
						path: "order",
						name: "couponPreferentialOrder",
						meta: {
							title: '一卡通订单列表'
						},
						component: () => import("@/views/reportForms/couponGift/viewPurchase/list.vue"),
					},
					{
						path: "viewRecharge",
						name: "reportFormsCouponGiftViewRecharge",
						meta: {
							title: '一卡通充值报表',
							permission:"reportForms_couponGift_viewRecharge"
						},
						component: () => import("@/views/reportForms/couponGift/viewRecharge/list.vue")
					},
					{
						path: "viewConsume",
						name: "reportFormsCouponGiftViewConsume",
						meta: {
							title: '一卡通消费报表',
							permission:"reportForms_couponGift_viewConsume"
						},
						component: () => import("@/views/reportForms/couponGift/viewConsume/list.vue")
					}
				]
			},
			{
				path: "snack",
				name: "snack",
				meta: {
					title: '优惠券管理',
					icon: "ShoppingCartOutlined",
					permission: "coupon_snack"
				},
				component: () => import("@/views/coupon/snack/list/list.vue"),
				children: [{
					path: "rules",
					name: "couponSnackRules",
					meta: {
						title: '优惠券政策',
						permission: "coupon_snack_rule"
					},
					component: () => import('@/views/coupon/snack/rules/list')
				},{
						path: "list",
						name: "couponSnackList",
						meta: {
							title: '优惠券列表',
							permission: "coupon_snack_list",
						},
						component: () => import("@/views/coupon/snack/list/list.vue")
					}, {
						path: "info",
						name: "couponSnackInfo",
						meta: {
							title:'优惠券发放信息',
							permission: "coupon_snack_info"
						},
						component: () => import("@/views/coupon/snack/info/list.vue")
					},
					{
						path: "use",
						name: "snackUse",
						meta: {
							title: '券使用列表',
							permission: "coupon_snack_use",
						},
						component: () => import("@/views/coupon/snack/use/list.vue")
					}, {
						path: "transfer",
						name: "snackTransfer",
						meta: {
							title: '券转赠列表',
							permission: "coupon_snack_transfer",
						},
						component: () => import("@/views/coupon/snack/transfer/list.vue")
					}, {
						path: "record",
						name: "snackRecordList",
						meta: {
							title: '券生成记录',
							permission: "coupon_snack_record",
						},
						component: () => import("@/views/coupon/snack/record/list.vue")
					}]
			},
			{
				path: "giftPack",
				name: "giftPack",
				meta: {
					title: '礼包管理',
					icon: "GiftOutlined",
					permission:"coupon_giftPack",
				},
				component: () => import("@/views/coupon/giftPack/list.vue"),
				children: [{
					path: 'list',
					name: "giftPackTask",
					meta: {
						title: '礼包列表',
						permission:"coupon_giftPack_list",
					},
					component: () => import("@/views/coupon/giftPack/list.vue"),
				}, {
					path: "giftPurchase",
					name: "couponGiftPackGiftPurchase",
					meta: {
						title: '礼包发放信息',
						permission:"coupon_giftPack_giftPurchase"
					},
					component: () => import("@/views/reportForms/couponGift/giftPurchase/list.vue")
				}]
			}]
	},
// 运营
	{
		path: "/operation",
		name: "operation",
		meta: {
			title: '运营',
			icon: "SyncOutlined",
			permission: "operation"
		},
		component: layout,
		children: [
			{
				path: "message",
				name: "operationMessage",
				meta: {
					title: '消息中心',
					icon: "MessageOutlined",
					permission: "operation_message",
				},
				component: () => import("@/views/operation/message/list/list.vue"),
				children: [{
					path: "list",
					name: "operationMessageList",
					meta: {
						title: '消息通知',
						permission: "operation_message_list",
					},
					component: () => import("@/views/operation/message/list/list.vue")
				}, {
					path: "notice",
					name: "operationMessageNotice",
					meta: {
						title: '公告管理',
						permission: "operation_message_notice",
					},
					component: () => import("@/views/operation/message/notice/list.vue")
				}]
			},
			{
				path: "activity",
				name: "operationActivity",
				meta: {
					title: '活动管理',
					icon: "BarsOutlined",
					permission: "operation_activity",
				},
				component: () => import("@/views/operation/activity/list.vue"),
				children: [{
					path: "list",
					name: "operationActivityList",
					meta: {
						title: '活动列表',
						permission: "operation_activity_list",
					},
					component: () => import("@/views/operation/activity/list.vue")
				}, {
					path: "draw",
					name: "operationActivityDraw",
					meta: {
						title: '抽奖活动',
						permission: "operation_activity_lottery"
					},
					component: () => import("@/views/operation/activity/draw/list.vue")
				}]
			}, {
				path: "copyWriting",
				name: "operationCopyWriting",
				meta: {
					title: '文案管理',
					icon: "ReadOutlined",
					permission: "operation_copyWriting",
				},
				component: () => import("@/views/operation/copyWriting/problemModule/list.vue"),
				children: [
					{
						path: "problemModule",
						name: "operationCopyWritingProblemModule",
						meta: {
							title: '问题模块管理',
							permission: "operation_copyWriting_problemModule",
						},
						component: () => import("@/views/operation/copyWriting/problemModule/list.vue")
					}, {
						path: "problem",
						name: "operationCopyWritingProblem",
						meta: {
							title: '问题管理',
							permission: "operation_copyWriting_problem",
						},
						component: () => import("@/views/operation/copyWriting/problem/list.vue")
					},
					// {
					// 	path: "certificate",
					// 	name: "operationCopyWritingCertificate",
					// 	meta: {
					// 		title: '证照信息',
					// 		permission: "operation_copyWriting_certificate",
					// 	},
					// 	component: () => import("@/views/operation/copyWriting/certificate/info.vue")
					// },
					// {
					// 	path: "enterprise",
					// 	name: "operationCopyWritingEnterprise",
					// 	meta: {
					// 		title: '企业文化',
					// 		permission: "operation_copyWriting_enterprise",
					// 	},
					// 	component: () => import("@/views/operation/copyWriting/enterprise/list.vue")
					// },
					{
						path: "agreement",
						name: "operationCopyWritingAgreement",
						meta: {
							title: '协议管理',
							permission: "operation_copyWriting_agreement"
						},
						component: () => import("@/views/operation/copyWriting/agreement/list.vue")
					}, {
						path: "manual",
						name: "operationCopyWritingManual",
						meta: {
							title: '会员手册',
							permission: "operation_copyWriting_manual",
						},
						component: () => import("@/views/operation/copyWriting/manual/info.vue")
					}, {
						path: "reason",
						name: "operationCopyWritingReason",
						meta: {
							title: '售后理由',
							permission: "operation_copyWriting_reason"
						},
						component: () => import("@/views/operation/copyWriting/reason/list.vue")
					}]
			}, {
				path: "integral",
				name: "operationIntegral",
				meta: {
					title: '积分管理',
					icon: "AccountBookOutlined",
					permission: "operation_integral",
				},
				component: () => import("@/views/operation/integral/temp.vue"),
				children: [{
					path: "temp",
					name: "operationIntegralTemp",
					meta: {
						title: '积分配置',
						permission: "operation_integral_configuration",
					},
					component: () => import("@/views/operation/integral/temp.vue")
				}]
			}, {
				path: "advertise",
				name: "operationAdvertise",
				meta: {
					title: '广告图管理',
					icon: "MenuOutlined",
					permission: "operation_advertise",
				},
				component: () => import("@/views/operation/market/advertise/list.vue"),
				children: [{
					path: "list",
					name: "operationAdvertiseList",
					meta: {
						title: '广告图列表',
						permission: "operation_advertise_list"
					},
					component: () => import("@/views/operation/market/advertise/list.vue")
				}]
			}, {
				path: "feedback",
				name: "operationFeedback",
				meta: {
					title: '用户反馈',
					icon: "AlignCenterOutlined",
					permission: "operation_feedback"
				},
				component: () =>import("@/views/operation/feedback/list.vue"),
				children: [{
					path: "list",
					name: "operationFeedbackList",
					meta: {
						title: '反馈列表',
						permission: "operation_feedback_list"
					},
					component: () =>import("@/views/operation/feedback/list.vue"),
				}]
			}, {
				path: "config",
				name: "operationConfig",
				meta: {
					title: '配置项管理',
					icon: "LinkOutlined",
					permission: "operation_config"
				},
				component: () => import("@/views/operation/config/edit.vue")
			}
		]},
// 用户
	{
		path: "/user",
		name: "user",
		meta: {
			title: '用户',
			icon: "UserOutlined",
			permission:"user"
		},
		component: layout,
		children: [{
			path: "manage",
			name: "userManage",
			meta: {
				title: '用户管理',
				icon: "UserAddOutlined",
				permission:"user_manage",
			},
			component: () => import("@/views/user/manage/list.vue"),
			children: [{
				path: "list",
				name: "userManageList",
				meta: {
					title: '用户列表',
					permission:"user_manage_list",
				},
				component: () => import("@/views/user/manage/list.vue")
			}]
		}, {
			path: "member",
			name: "userMember",
			meta: {
				title: '会员中心',
				icon: "CrownOutlined",
				permission:"user_member",
			},
			component: () => import("@/views/user/member/list.vue"),
			children: [{
				path: "list",
				name: "userMemberList",
				meta: {
					title: '会员等级管理',
					permission:"user_member_list",
				},
				component: () => import("@/views/user/member/list.vue")
			}, {
				path: "equity",
				name: "userMemberEquity",
				meta: {
					title: '会员权益',
					permission:"user_member_equity"
				},
				component:() =>import("@/views/user/equity/list.vue")
			}]
		}, {
			path: "task",
			name: "userManageTask",
			meta: {
				title: '会员任务',
				icon: "ThunderboltOutlined",
				permission: "user_task"
			},
			component: () => import("@/views/user/task/manage.vue"),
			children: [{
				path: "list",
				name: "userManageTaskList",
				meta: {
					title: '任务列表',
					permission: "user_task_list"
				},
				component: () => import("@/views/user/task/manage.vue")
			}]
		}, {
			path: "feature",
			name: "userManageFeature",
			meta: {
				title: '特色会员',
				icon: "MoneyCollectOutlined",
				permission: "user_feature"
			},
			component: () => import("@/views/user/grant/list.vue"),
			children: [{
				path: 'grant',
				name: "userManageFeatureGrant",
				meta: {
					title: '特色会员发放',
					permission: "user_feature_grant"
				},
				component: () => import("@/views/user/grant/list.vue")
			}, {
				path: "generate",
				name: "userMemberGenerate",
				meta: {
					title: '兑换会员生成记录',
					permission: 'user_member_generate'
				},
				component: () => import("@/views/user/generate/list.vue")
			}, {
				path: "list",
				name: "userManageFeatureList",
				meta: {
					title: '特色会员列表',
					permission: "user_feature_list"
				},
				component: () => import("@/views/user/feature/list.vue")
			}, {
				path: 'order',
				name: "userManageFeatureOrder",
				meta: {
					title: '特色会员订单',
					permission: "user_feature_order"
				},
				component: () => import("@/views/user/feature/order.vue")
			}]
		}, {
			path: "record",
			name: "userRecord",
			meta: {
				title: '变更记录',
				icon: "DollarOutlined",
				permission:"user_property"
			},
			component: () => import("@/views/user/record/record.vue"),
			children: [{
				path: "record",
				name: "userRecordRecord",
				meta: {
					title: '资产变更列表',
					permission:"user_property_list",
				},
				component: () => import("@/views/user/record/record.vue")
			}, {
				path: 'pointRecord',
				name: "userRecordPointRecord",
				meta: {
					title: '用户积分变更记录',
					permission: "user_property_point"
				},
				component: () => import('@/views/user/record/pointRecord.vue')
			}]
		},
		// {
		// 	path: "characteristic",
		// 	name: "userCharacteristic",
		// 	meta: {
		// 		title: '特色会员信息',
		// 		icon: "ReadOutlined",
		// 		permission:"user_characteristic"
		// 	},
		// 	component: () => import("@/views/reportForms/vip/list.vue"),
		// },
		]
	},
// 报表
	{
		path: "/reportForms",
		name: "reportForms",
		meta: {
			title: '报表',
			icon: "SnippetsOutlined",
			permission:"reportForms"
		},
		component: layout,
		children: [{
			path: "finance",
			name: "reportFormsFinance",
			meta: {
				title: '财务报表',
				icon: "AccountBookOutlined",
				permission:"reportForms_finance"
			},
			component: () => import("@/views/reportForms/finance/sale/list.vue"),
			children: [
				{
				path: "sale",
				name: "reportFormsFinanceSale",
				meta: {
					title: '营收汇总报表',
					permission:"reportForms_finance_sale"
				},
				component: () => import("@/views/reportForms/finance/sale/list.vue")
			},
			{
				path: 'film',
				name: "reportFormsFinanceFilm",
				meta: {
					title: '影票明细报表',
					permission: 'reportForms_finance_film'
				},
				component: () => import("@/views/reportForms/finance/detail/list"),
			},
			{
				path: 'snack',
				name: "reportFormsFinanceSnack",
				meta: {
					title: '卖品明细报表',
					permission: 'reportForms_finance_snack'
				},
				component: () => import("@/views/reportForms/finance/detail/list"),
			},
			{
				path: 'goods',
				name: "reportFormsFinanceGoods",
				meta: {
					title: '商品明细报表',
					permission: 'reportForms_finance_goods'
				},
				component: () => import("@/views/reportForms/finance/detail/list"),
			},
			{
				path: 'perform',
				name: "reportFormsFinancePerform",
				meta: {
					title: '演出明细报表',
					permission: 'reportForms_finance_perform'
				},
				component: () => import("@/views/reportForms/finance/detail/list"),
			},
			{
				path: 'cardSale',
				name: "reportFormsFinanceCardSale",
				meta: {
					title: '一卡通销售明细报表',
					permission: 'reportForms_finance_cardSale'
				},
				component: () => import("@/views/reportForms/finance/detail/list"),
			},
			{
				path: 'cardRecharge',
				name: "reportFormsFinanceCardRecharge",
				meta: {
					title: '一卡通充值明细报表',
					permission: 'reportForms_finance_cardRecharge'
				},
				component: () => import("@/views/reportForms/finance/detail/list"),
			},
			{
				path: 'member',
				name: "reportFormsFinanceMember",
				meta: {
					title: '特色会员明细报表',
					permission: 'reportForms_finance_member'
				},
				component: () => import("@/views/reportForms/finance/detail/list"),
			},
			{
				path: 'coupon',
				name: "reportFormsFinanceCoupon",
				meta: {
					title: '券包销售明细报表',
					permission: 'reportForms_finance_coupon'
				},
				component: () => import("@/views/reportForms/finance/detail/list"),
			}
		]

		}, {
			path: "sale",
			name: "reportFormsSale",
			meta: {
				title: '销售明细报表',
				icon: "CalendarOutlined",
				permission:"reportForms_sale"
			},
			component: () => import("@/views/reportForms/sale/film/list.vue"),
			children: [{
				path: "filmOrder",
				name: "reportFormsSaleFilmOrder",
				meta: {
					title: '影票订单报表',
					permission:"reportForms_sale_filmOrder"

				},
				component: () => import("@/views/reportForms/sale/filmOrder/list.vue")
			}, {
				path: "film",
				name: "reportFormsSaleFilm",
				meta: {
					title: '影票报表',
					permission:"reportForms_sale_film"
				},
				component: () => import("@/views/reportForms/sale/film/list.vue")
			},
			// 	{
			// 	path: "goods",
			// 	name: "reportFormsSaleGoods",
			// 	meta: {
			// 		title: '商品报表',
			// 		permission:"reportForms_sale_goods"
			// 	},
			// 	component: () => import("@/views/mall/order/goodsOrder/list.vue")
			// },
			// {
			// 	path: "sales",
			// 	name: "reportFormsSaleSales",
			// 	meta: {
			// 		title: '卖品报表',
			// 		permission:"reportForms_sale_sales"
			// 	},
			// 	component: () => import("@/views/reportForms/sale/sales/list.vue")
			// }
				]
		}, {
			path: "signature",
			name: "reportFormsSignature",
			meta: {
				title: '退改签明细报表',
				icon: "CarryOutOutlined",
				permission:"reportForms_signature"
			},
			component: () => import("@/views/reportForms/signature/return/list.vue"),
			children: [{
				path: "return",
				name: "reportFormsSignatureReturn",
				meta: {
					title: '退票报表',
					permission:"reportForms_signature_return"
				},
				component: () => import("@/views/reportForms/signature/return/list.vue")
			}, {
				path: "rebook",
				name: "reportFormsSignatureRebook",
				meta: {
					title: '改签报表',
					permission:"reportForms_signature_rebook"
				},
				component: () => import("@/views/reportForms/signature/rebook/list.vue")
			}]
		},
		// {
		// 	path: "couponGift",
		// 	name: "reportFormsCouponGift",
		// 	meta: {
		// 		title: '一卡通报表',
		// 		icon: "CodeOutlined",
		// 		permission:"reportForms_couponGift"
		// 	},
		// 	component: () => import("@/views/reportForms/couponGift/viewPurchase/list.vue"),
		// 	children: [{
		// 		path: "viewRecharge",
		// 		name: "reportFormsCouponGiftViewRecharge",
		// 		meta: {
		// 			title: '一卡通充值报表',
		// 			permission:"reportForms_couponGift_viewRecharge"
		// 		},
		// 		component: () => import("@/views/reportForms/couponGift/viewRecharge/list.vue")
		// 	}, {
		// 		path: "viewConsume",
		// 		name: "reportFormsCouponGiftViewConsume",
		// 		meta: {
		// 			title: '一卡通消费报表',
		// 			permission:"reportForms_couponGift_viewConsume"
		// 		},
		// 		component: () => import("@/views/reportForms/couponGift/viewConsume/list.vue")
		// 	}]
		// },
		// {
		// 	path: 'vip',
		// 	name: 'reportFormsVip',
		// 	meta: {
		// 		title: '特色会员报表',
		// 		icon: 'CrownOutlined'
		// 	},
		// 	component: () => import("@/views/reportForms/vip/list.vue"),
		// 	children: [{
		// 		path: 'activate',
		// 		name: 'reportFormsVipActivate',
		// 		meta: {
		// 			title: '特色会员开通报表'
		// 		},
		// 		component: () => import("@/views/reportForms/vip/list.vue"),
		// 	}]
		// },
		{
			path: 'bill',
			name: 'reportFormsBill',
			meta: {
				title: '发票管理',
				icon: "ProjectOutlined",
				permission: "report_bill"
			},
			component: () => import("@/views/reportForms/bill/list.vue"),
			children: [{
				path: 'billList',
				name: 'reportFormsBillList',
				meta: {
					title: '申请列表',
					permission: "report_bill_list"
				},
				component: () => import("@/views/reportForms/bill/list.vue"),
			}]
		}]
	},
// 演出中心
	{
		path: "/performance",
		name: "performance",
		meta: {
			title: '演出',
			icon: "VideoCameraOutlined",
			permission:"performance"
		},
		component: layout,
		children: [{
			path: "center",
			name: "center",
			meta: {
				title: '演出管理',
				icon: "VideoCameraOutlined",
				permission:"performance_manage",
			},
			component: () => import("@/views/performance/list/list.vue"),
			children: [{
				path: "list",
				name: "performanceList",
				meta: {
					title: '演出列表',
					permission:"performance_manage_list",
				},
				component: () => import("@/views/performance/list/list.vue")
			}, {
				path: "classify",
				name: "classify",
				meta: {
					title: '演出分类',
					permission:"performance_manage_classify",
				},
				component: () => import("@/views/performance/classification/list.vue")
			}, {
				path: 'site',
				name: 'performanceSite',
				meta: {
					title: '场地管理',
					permission: "performance_manage_site"
				},
				component: ()=> import("@/views/performance/site/index.vue")
			}, {
				path: "navigation",
				name: "performanceNavigation",
				meta: {
					title: '首页导航',
					permission:"performance_manage_navigation",
				},
				component: () => import("@/views/performance/navigation/list.vue")
			}, {
				path: "label",
				name: "performanceLabel",
				meta: {
					title: '演出标签',
					permission:"performance_manage_label",
				},
				component: () => import("@/views/performance/label/label.vue")
			}, {
				path: "notice",
				name: "notice",
				meta: {
					title: '演出须知',
					permission:"performance_manage_notice",
				},
				component: () => import("@/views/performance/notice/notice.vue")
			}, {
				path: "service",
				name: "performanceService",
				meta: {
					title: '演出服务',
					permission:"performance_manage_service",
				},
				component: () => import("@/views/performance/service/service.vue")
			}]
		}, {
			path: "order",
			name: "performanceOrder",
			meta: {
				title: '订单管理',
				icon: "UnorderedListOutlined",
				permission:"performance_order",
			},
			component: () => import("@/views/performance/order/list.vue"),
			children: [{
				path: "list",
				name: "performanceOrderList",
				meta: {
					title: '演出订单',
					permission:"performance_order_list",
				},
				component: () => import("@/views/performance/order/list.vue")
			},
			// {
			// 	path: "ticket",
			// 	name: "performanceOrderTicket",
			// 	meta: {
			// 		title: '观演票订单'
			// 	},
			// 	component: () => import("@/views/performance/order/ticketOrder/list.vue")
			// },
			{
				path: "seat",
				name: "performanceOrderSeat",
				meta: {
					title: '票座位列表',
					permission: 'performance_order_seat'
				},
				component: () => import("@/views/performance/order/seat/list.vue")
			}, {
				path: "afterSale",
				name: "performanceOrderAfterSale",
				meta: {
					title: '售后列表',
					permission:"performance_order_afterSale",
				},
				component: () => import("@/views/performance/order/afterSale/list.vue")
			}, {
				path: 'refundRules',
				name: "performanceOrderRefundRules",
				meta: {
					title: '售后规则',
					permission: 'performance_order_refundRules'
				},
				component: () => import("@/views/performance/order/refundRules/list.vue")
			}]
		}, {
			path: "special",
			name: "performanceSpecial",
			meta: {
				title: '演出专场',
				icon: "VideoCameraAddOutlined",
				permission:"performance_special",
			},
			component: () => import("@/views/performance/special/mustSnatch/list.vue"),
			children: [{
				path: "mustSnatch",
				name: "performanceSpecialMustSnatch",
				meta: {
					title: '今日必抢',
					permission:"performance_special_mustSnatch",
				},
				component: () => import("@/views/performance/special/mustSnatch/list.vue")
			}, {
				path: "introduction",
				name: "performanceSpecialIntroduction",
				meta: {
					title: '演出攻略',
					permission:"performance_special_introduction",
				},
				component: () => import("@/views/performance/special/introduction/list.vue")
			},
			// {
			// 	path: "recommend",
			// 	name: "performanceSpecialRecommend",
			// 	meta: {
			// 		title: '推荐演出列表',
			// 		permission:"performance_special_recommend",
			// 	},
			// 	component: () => import("@/views/performance/special/recommend/list.vue")
			// },
			]
		},
		// {
		// 	path: "perform",
		// 	name: "reportFormsSignaturePerform",
		// 	meta: {
		// 		title: '演出信息表',
		// 		icon: "ReadOutlined",
		// 		permission:"performance_performInfo"
		// 	},
		// 	component: () => import("@/views/reportForms/session/perform/list.vue")
		// },
		]
	},
// 评价中心
	{
		path: "/comment",
		name: "comment",
		meta: {
			title: '评价',
			icon: "EditOutlined",
			permission:"comment",
		},
		component: layout,
		children: [{
			path: "film",
			name: "filmComment",
			meta: {
				title: '评价列表',
				icon: "FormOutlined",
				permission:"comment_center",
			},
			component: () => import("@/views/comment/film/center.vue"),
		}, {
			path: "check",
			name: "check",
			meta: {
				title: '评价评论审核',
				icon: "PauseCircleOutlined",
				permission:"comment_check",
			},
			component: () => import("@/views/comment/check/check.vue")
		}, {
			path: "list",
			name: "checkList",
			meta: {
				title: '节目列表',
				icon: "UnorderedListOutlined",
				permission:"comment_list",
			},
			component: () => import("@/views/comment/list/program.vue")
		}]
	},
// 核销中心
	{
		path: "/sales",
		name: "sales",
		meta: {
			title: '核销中心',
			icon: "SafetyCertificateOutlined",
			permission: 'verify_sale'
		},
		component: layout,
		children: [{
			path: "manage",
			name: "verifySaleManage",
			meta: {
				title: '核销管理',
				icon: "TransactionOutlined",
				permission: 'verify_sale_manage'
			},
			component: () => import("@/views/sales/manage/goods/goods.vue"),
			children: [{
				path: "goods",
				name: "verifySaleStore",
				meta: {
					title: '商品核销',
					permission: 'verify_sale_manage_goods'
				},
				component: () => import("@/views/sales/manage/goods/goods.vue"),
			}, {
				path: "saleGoods",
				name: "verifySaleGoods",
				meta: {
					title: '卖品核销',
					permission: 'verify_sale_manage_snack'
				},
				component: () => import("@/views/sales/manage/saleGoods/saleGoods.vue"),
			} , {
				path: "salePerform",
				name: "verifySalePerform",
				meta: {
					title: '演出核销',
					permission: 'verify_sale_manage_perform'
				},
				component: () => import("@/views/sales/manage/salePerform/index.vue"),
			}]
		}]
	},
	//
	// {
	// 	path: "/:pathMatch(.*)*",
	// 	name: "404",
	// 	hidden: true,
	// 	meta: {
	// 		title: '路由不存在'
	// 	},
	// 	component: () => import("@/views/exception/404"),
	// },
];

const routes = [
	// ...asyncRoutes,
	...constantRoutes,
];

export const router = createRouter({
	history: createWebHistory(process.env.NODE_ENV === 'production' ? '/dashboard/' : '/'),
	routes,
	scrollBehavior() {
		// 始终滚动到顶部
		// see https://next.router.vuejs.org/zh/guide/advanced/scroll-behavior.html
		return {
			top: 0
		};
	},
});

// 初始化路由
initRouter();

export default router;
